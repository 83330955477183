<template>
    <div id="plannedShift">
        <jy-query ref="form" :model="formData">
            <jy-query-item prop="routeId" label="线路">
                <el-select v-model="formData.routeId">
                    <el-option v-for="one in routeList" v-bind:key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item prop="vehicleNo" label="车辆">
                <el-input v-model="formData.vehicleNo"></el-input>
            </jy-query-item>
            <jy-query-item prop="driverNa" label="司机">
                <el-input v-model="formData.driverNa"></el-input>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('scheduleSchedulingPlannedShiftList')">查询</el-button>
                <el-button type="primary" @click="resetForm('form')" v-if="btnexist('scheduleSchedulingPlannedShiftReset')">重置</el-button>
                <el-row>
                    <el-button type="primary" @click="addPlanShift()" v-if="btnexist('scheduleSchedulingPlannedShiftInsertPlan')">新增计划班次</el-button>
                    <el-button type="primary" @click="addUdate()" v-if="btnexist('scheduleSchedulingPlannedShiftInsert')">新增班次</el-button>
                </el-row>
                <el-row>
                    <el-button type="primary" @click="importFun" v-if="btnexist('scheduleSchedulingPlannedShiftImport')">导入</el-button>
                    <el-button type="primary" @click="rotationTable" v-if="btnexist('scheduleSchedulingPlannedShiftRotation')">实际轮休情况</el-button>
                </el-row>
            </template>
        </jy-query>
        <rotation-table ref="rotationTable"></rotation-table>
        <time-calendar ref="timeCalendar" @choose="choose" @changeMonth="changeMonth"></time-calendar>
        <time-table :list="list" :operationList="operationList" @dragItem="dragItem" :isInit="isInit" :isShowIcon="false"></time-table>
        <add-plan-shift ref="addPlanShift" @updateList="oncheck"></add-plan-shift>
        <add-shift ref="npm" @updateList="update"></add-shift>
        <jy-import ref="jyImport" :headers="uploadHeaders" :action="action" @downloadTemplate="downloadTemplate" @getElxData="getElxData" @confirm="confirm" :successLength="correctCount" :errList="listError" :status.sync="importStatus"></jy-import>
    </div>
</template>
<script>
import timeTable from '@/components/pages/admin/common/timeTable/timeTable'
import timeCalendar from '@/components/pages/admin/common/timeTable/timeCalendar'
import addPlanShift from '@/components/pages/admin/scheduling/plannedShift/addPlanShift'
import addShift from '@/components/pages/admin/scheduling/plannedShift/addShift'
import rotationTable from '@/components/pages/admin/scheduling/plannedShift/rotationTable'
import {btnMixins} from '@/common/js/button.mixin'
export default {
    data() {
        return {
            schemeId:'',

            formData: {
                routeId: '',
                vehicleNo: '',
                driverNa: '',
            },
            routeList: [],

            beginDate:'',
            endDate:'',
            chooseDate:'',
            list:[],
            driverList:[],
            operationList:[],
            rotationList:[],
            monthShift:[],
            isInit:true,

            //导入
            uploadHeaders: {
                token: this.$util.getStor('token'),
            },
            action: '/IntelligentArrangeSystem/waybillPlan/importSitesCach',
            importId: '',
            listError: [],
            correctCount: '',
            errorCount: '',

            importStatus: 1,

            btnMenuId:'scheduleSchedulingPlannedShift',
        }
    },
    mixins:[btnMixins],
    components: {
        timeTable,
        timeCalendar,
        addPlanShift,
        addShift,
        rotationTable,
    },
    async created(){
        this.seOperation()
        this.getDate()
        await this.getRouteList()
        this.getList()
    },
    methods:{
        seOperation(){
            this.operationList = [
                {
                    name: '发车通知',
                    fun: (item) => {
                        this.sedCar(item)
                    },
                    isShow: () => {
                        return true
                    }
                },
                {
                    name: '编辑',
                    fun: (item) => {
                        this.addUdate(item)
                    },
                    isShow: () => {
                        return true
                    }
                },
                {
                    name: '复制',
                    fun: (item) => {
                        this.copy(item)
                    },
                    isShow: () => {
                        return true
                    }
                },
                {
                    name: '删除',
                    fun: (item) => {
                        this.del(item)
                    },
                    isShow: () => {
                        return true
                    }
                },
            ]
        },
        async getRouteList() {
            let url = '/route/info/queryRouteInfoByOrgIds'
            await this.$http.post(url).then(({ detail }) => {
                this.routeList = detail
                if(this.routeList.length){
                    this.formData.routeId = this.routeList[0].routeId
                }
            })
        },
        getDate(date){
            let now = date || new Date()
            let nowMonth = now.getMonth()
            let nowYear = now.getFullYear()
            this.chooseDate =  now.Format('yyyy-MM-dd')
            this.beginDate = new Date(nowYear, nowMonth, 1).Format('yyyy-MM-dd')
            this.endDate = new Date(nowYear, nowMonth+1, 0).Format('yyyy-MM-dd')
            this.$nextTick(()=>{
                this.$refs.timeCalendar.init(this.beginDate,this.endDate,this.chooseDate)
            })
        },
        getList() {
            let option = {
                ...this.formData,
                oDate:this.chooseDate,
                startTime:this.beginDate,
                endTime:this.endDate,
            }
            let url = '/waybillPlan/queryPage'
            this.$http.post(url, option).then(({ detail }) => {
                this.list = detail
                console.log(detail)
            })
            console.log(this.list)
        },
        // 查询表格
        oncheck() {
            this.isInit = true
            this.getList()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            if(this.routeList.length){
                this.formData.routeId = this.routeList[0].routeId
            }
            this.getDate()
            this.oncheck()
        },
        addPlanShift(){
            this.$refs.addPlanShift.init()
        },
        addUdate(option) {
            if (option) {
                this.$refs.npm.init(option, 'update')
            } else {
                let routeObj = this.routeList.filter(item=>item.routeId == this.formData.routeId)[0]
                console.log(routeObj)
                let option = {
                    routeId:routeObj.routeId,
                    routeNa:routeObj.cName,
                    orgId:routeObj.orgId,
                    orgPath:routeObj.orgPath,
                    oDate:this.chooseDate,
                    schemeId:this.schemeId,
                }
                this.$refs.npm.init(option,'add')
            }
        },
        update(id){
            if(id){
                this.schemeId = id
            }
            this.isInit = false
            this.getList()
        },
        choose(e){
            this.chooseDate = e.date
            this.isInit = true
            this.getList()
        },
        clickItem(e){
            console.log(e)
        },
        dragItem(e){
            this.isInit = false
            let url = '/waybillPlan/update'
            let errOption = {
                err:res=>{
                    console.log(res)
                    this.$message({
                        message: res.error,
                        type: 'error',
                    });
                    this.getList()
                },
            }
            this.$http.post(url,e,errOption).then(res=>{
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.getList()
            })
        },
        sedCar(item){
            this.isInit = false
            console.log(item)
            let url = '/waybillPlan/depNotice'
            let option = {
                waybillId:item.waybillId
            }
            this.$http.post(url,option).then(res=>{
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.getList()
            })
        },
        copy(item){
            this.isInit = false
            let url = '/waybillPlan/add'
            let option = {
                ...item,
                schemeId:this.schemeId,
            }
            this.$http.post(url,option).then(res=>{
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.getList()
            })
        },
        del(item){
            this.isInit = false
            this.$confirm('是否删除该班次?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let url = '/waybillPlan/del'
                    let option = {
                        waybillId:item.waybillId
                    }
                    this.$http.post(url,option).then(res=>{
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.getList()
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        changeMonth(option){
            this.beginDate = option.beginDate
            this.endDate = option.endDate
            this.chooseDate = new Date(option.beginDate).Format('yyyy-MM-dd')
        },
        async rotationTable(){
            await this.getRotationList()
            await this.getMonthAllshift()
            let driverShiftList=[]
            this.monthShift.forEach(item=>{
                if(item.driverId && !driverShiftList.some(one=>one.driverId == item.driverId)){
                    driverShiftList.push(
                        {
                            driverId:item.driverId,
                            driverNa:item.driverNa,
                            workData:this.monthShift.filter(val=>val.driverId == item.driverId).map(val=>{
                                return {
                                    oDate:val.oDate,
                                    dTime:Number(val.dTime.split(':').join('')),
                                }
                            }),
                        }
                    )
                }
            })
            console.log(this.rotationList)
            driverShiftList.forEach(item=>{
                let date = []
                this.rotationList.forEach(one=>{
                    let wList = item.workData.filter(val=>val.oDate == one.date)
                    if(!wList.length){
                        date.push({
                            date:one.date,
                            typeName:'休',
                        })
                    }else{
                        if(one.limitT){
                            let morning = !wList.some(val=>val.dTime<one.limitT)
                            let afternoon = !wList.some(val=>val.dTime>one.limitT)
                            if(morning){
                                date.push({
                                    date:one.date,
                                    typeName:'上午',
                                })
                            }
                            if(afternoon){
                                date.push({
                                    date:one.date,
                                    typeName:'下午',
                                })
                            }
                        }
                    }
                })
                item.date = date
            })

            let option = {
                beginDate:this.beginDate,
                endDate:this.endDate,
                driverList:driverShiftList,
            }
            this.$refs.rotationTable.init(option)
        },
        async getRotationList(){
            let url = '/rotation/set/queryList'
            let option = {
                routeId:this.formData.routeId,
                startTime:this.beginDate,
                endTime:this.endDate,
            }
            await this.$http.post(url,option).then(({detail})=>{
                if(detail){
                    let dlist = this.$util.getBetweenDateList(this.beginDate,this.endDate)
                    let newDList = dlist.map(one=>{
                        let obj = detail.filter(item=>item.rotationPlanT == one.date)[0]
                        return {
                            date:one.date,
                            limitT:obj?Number(obj.limitT.split(':').join('')):'',
                        }
                    })
                    this.rotationList = newDList
                }else{
                    this.rotationList = []
                }
            })
        },
        async getMonthAllshift(){
            let url = '/waybillPlan/queryListByMonth'
            let option = {
                routeId:this.formData.routeId,
                startTime:this.beginDate,
                endTime:this.endDate,
            }
            await this.$http.post(url,option).then(({detail})=>{
                if(detail){
                    this.monthShift = detail
                }else{
                    this.monthShift = []
                }
            })
        },

        //导入
        importFun() {
            this.$refs.jyImport.init()
        },
        downloadTemplate() {
            let url = '/waybillPlan/downloadTemplate'
            this.$http
                .post(url, {}, { type: 'format', isExcel: true })
                .then(res => {
                    this.$util.funDownload(res.data, '计划班次模板')
                })
        },
        getElxData(data) {
            console.log(data.detail)
            this.importId = data.detail.importId
            let url = '/waybillPlan/importSitesCheck'
            let params = {
                importId: data.detail.importId,
            }
            this.$http.post(url, params).then(data => {
                let { detail } = data
                this.listError = detail.listError
                this.correctCount = detail.correctCount
                this.errorCount = detail.errorCount
            })
        },
        confirm() {
            let params = {
                importId: this.importId,
            }
            let url = '/waybillPlan/importSitesData'
            this.$http.post(url, params).then(
                data => {
                    if (data.code == 0) {
                        this.importStatus = 1
                        this.oncheck()
                    }
                },
                () => {
                    this.importStatus = -1
                }
            )
        },
    },
}
</script>
<style lang="scss" scoped="plannedShift">
#plannedShift{
}
</style>